body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background-color: #ee7023;
    user-select: none;
}

* {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

#root-grid {
    display: grid;
    grid-template-columns: 1fr 1300px;
    align-items: center;
    /* width: 100vw;
    height: 100vh; */
    width: 1920px;
    height: 1080px;
    position: fixed;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}