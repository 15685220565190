.countdown {
    width: 180px;
    height: 180px;
    display: flex;
    border: 17px solid #ee7023;
    background-color: #fff;
    font-size: 60px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    /* transform: translate(0, -50%); */
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    vertical-align: text-bottom;
}
.countdown:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
.countdown:active {
    transform: scale(0.9);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.countdown span {
    font-size: 0.7em;
    position: relative;
    top: 6px
}