.award {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* gap: 10px; */
}

.award h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 300;
    transform: translate(0, -50%);
}

.award h1 {
    margin: 0;
    font-weight: 900;
    letter-spacing: .15em;
    font-size: 48px;
    transform: translate(0, -13px);
}

.award.--platinum {
    background: linear-gradient(-45deg, #E5E4E2,
            #DADADA,
            #E5E4E2,
            #BCC6CC,
            #E5E4E2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.award.--gold {
    background: linear-gradient(-45deg, #B8860B,
            #DAA520,
            #B8860B,
            #FFD700,
            #B8860B);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.award.--silver {
    background: linear-gradient(-45deg, #C0C0C0,
            #D8D8D8,
            #C0C0C0,
            #D3D3D3,
            #C0C0C0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.award.--bronze {
    background: linear-gradient(-45deg, #CD7F32,
            #D68A45,
            #CD7F32,
            #DA8A67,
            #CD7F32);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.award.--hide {
    display: none;
}