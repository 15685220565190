.popup2 {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    overflow: hidden;
}

.popup2::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, .75);
    backdrop-filter: blur(10px);
    pointer-events: none;
    opacity: 0;
    transition: all 0.5s;
    z-index: 0;
}

.popup2__wrapper-content {
    border-radius: 14px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    max-width: 700px;
    padding: 15px;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: translate(0, -100%);
    transition: all 0.5s;
    z-index: 1;
    background-color: rgb(255 255 255 / 30%);
    overflow: hidden;
}

.popup2__content {
    border-radius: 10px;
    max-width: 700px;
    width: 100%;
    text-align: center;
    background-color: #ee7023;
    overflow: hidden;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}


.popup2__header {
    padding: 40px 20px;
    position: relative;
    overflow: hidden;
}

.popup2__header::before {
    position: absolute;
    top: -37.4vw;
    left: 0;
    width: 100vw;
    height: 50vw;
    content: '';
    display: block;
    background-color: #fff;
    z-index: 0;
    border-radius: 100%;
    transform: translate(-32%, 0);
}

.popup2__header img {
    max-width: 250px;
    display: block;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}

.popup2__main {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.popup2__main h1 {
    margin: 0;
    font-size: 36px;
    border: 3px solid #fff;
    background-color: rgba(255, 255, 255, .25);
    padding: 20px 40px;
    border-radius: 10px;
    font-weight: 300;
}

.popup2__main p {
    font-weight: 300;
}

.popup2.--show {
    pointer-events: all;
}

.popup2.--show::after {
    opacity: 1;
}

.popup2.--show .popup2__wrapper-content {
    opacity: 1;
    transform: translate(0, 0);
}

.popup2.--show .popup2__wrapper-content:active {
    transform: scale(0.9);
}