.cell {
    --base-color: #009fe3;
    --base-color-hover: #009fe3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
    box-sizing: border-box;
    user-select: none;
    color: #333;
    cursor: pointer;
    padding: 2px;
}

.cell:hover .cell__inner {
    transform: scale(.95);
}

.cell__inner {
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    transition: all 0.5s;
    will-change: transform;
}

.cell.highlight .cell__inner {
    background-color: var(--base-color-hover);
    color: #fff;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.cell.use.highlight .cell__inner {
    background-color: var(--base-color);
    color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    transform: scale(.95);
    z-index: 1;
    font-weight: 700;
    border-radius: 20%;
}

.cell.show .cell__inner {
    background-color: rgba(255, 255, 255, 0.75);
    color: #333;
    color: #fff;
    color: var(--base-color);
    opacity: 0.7;
    font-weight: 900;
}