.word-list {
}

.word-list ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0;
    align-items: center;
    justify-content: center;
}

.word-list li {
    padding: 10px 20px;
    transition: all 0.6s;
    font-weight: 900;
    pointer-events: none;
    font-size: 16px;
    color: #333;
    text-transform: uppercase;
    user-select: none;
    letter-spacing: 0.05em;
}

.word-list li.select {
    opacity: 0.5;
    transform: scale(0.9);
    text-decoration: line-through;
    font-weight: 400;
}