.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.content::after {
    display: block;
    content: '';
    position: absolute;
    left: -97vw;
    width: 100vw;
    height: 166vw;
    background-color: #fff;
    border-radius: 100%;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
}