.highligh-line {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-color: transparent;
    z-index: 1;
}

.highligh-line line {
    stroke: rgba(238,
            112,
            35, 0.5);
    stroke-width: 50;
    stroke-linecap: round;
}